import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"

export const SegmentAdd = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{

        name: string;
       
    }>({
        name: "",
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        useCases?.segmentUseCase.CreateSegment(form.name!, () => { navigate(-1) })
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Сегменты /</span> Добавить сегмент</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Добавление сегмента</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "name",
                                            title: "Название проекта",
                                            placeholder: "Укажите название проекта",
                                            value: form.name,
                                            type: FiledType.Text,
                                        },
                                    ]}
                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
