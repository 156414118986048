export class SegmentWithLeadsCount {

    private id: string; 
    private name: string; 
    private isIntegrationActive: boolean; 
    private leadsCount: number;
    private lastProcessedLeadDate?: Date; 
    private createdAt: Date;
    private pid?: string;


    constructor( id: string, name: string, isIntegrationActive: boolean, leadsCount: number, createdAt: Date, pid: string, lastProcessedLeadDate?: Date) {
        this.id = id;
        this.name = name;
        this.isIntegrationActive = isIntegrationActive;
        this.leadsCount = leadsCount;
        this.createdAt = createdAt;
        this.lastProcessedLeadDate = lastProcessedLeadDate;
        this.pid = pid;
    }
    
    public Id = (): string => {
        return this.id
    }

    public Name = (): string => {
        return this.name
    }

    public Pid = (): string | undefined => {
        return this.pid
    }

    public IsIntegrationActive = (): boolean => {
        return this.isIntegrationActive
    }

    public LeadsCount = (): number => {
        return this.leadsCount
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public LastProcessedLeadDate = (): Date | undefined => {
        return this.lastProcessedLeadDate
    }
}