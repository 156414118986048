import { Segment } from "../../domain/segment/segment";
import { SegmentWithLeadsCount } from "../../domain/segment/segment_with_leads_count";
import { SegmentActionTypes } from "../action-types";
import { SegmentActions } from "../actions";
import produce from 'immer';


interface SegmentState {
    loading: boolean | null;
    segments: SegmentWithLeadsCount[] | null,
    count: number,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: SegmentState = {
    loading: null,
    segments: null,
    count: 0,
    error: null,
    entityLoading: false,
    entityError: null,
}



const segmentReducer = produce((state: SegmentState = initialState, action: SegmentActions) => {
    switch (action.type) {
        case SegmentActionTypes.SEGMENT_REQUEST_SEND:
            state.loading = true;
            return state;
        case SegmentActionTypes.SEGMENT_SUCCESS:
            state.loading = false;
            state.segments = action.payload.segments;
            state.count = action.payload.count;
            return state;
        case SegmentActionTypes.SEGMENT_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case SegmentActionTypes.SEGMENT_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case SegmentActionTypes.SEGMENT_CREATE_SUCCESS:
            state.entityLoading = false
            if (state.segments) {
                state.segments.push(toSegmentWithLeadsCount(action.payload))
            }
            state.entityError = ""
            return state;
        case SegmentActionTypes.SEGMENT_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case SegmentActionTypes.SEGMENT_DELETE_SUCCESS:
            state.segments = state.segments!.filter((segments) => segments.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

const toSegmentWithLeadsCount = (segment: Segment) : SegmentWithLeadsCount => {
    return new SegmentWithLeadsCount(segment.Id(), segment.Name(), segment.IsIntegrationActive(), 0, segment.CreatedAt(), segment.Pid())
}
export default segmentReducer;
