import React, { useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { Menu } from "../../../components/menu"
import { SegmentDomain } from "../../../../domain/segment/segment_domain"
import { SegmentPhone } from "../../../../domain/segment/segment_phone"
import { Check, Trash } from "tabler-icons-react"

export const InputParamsEdit = () => {
    let useCases = useContext(UseCasesContext)
    const navigate = useNavigate();
    const [errorsDomainCreate, setErrorDomainsCreate] = useState<string[]>([])
    const [errorsPhoneCreate, setErrorPhoneCreate] = useState<string[]>([])
    let { id } = useParams();
    const [domains, setDomains] = useState<SegmentDomain[]>([])
    const [phones, setPhones] = useState<SegmentPhone[]>([])
    const pidList = {
            kinetic1: 'мегафон',
            kinetic2:  'мтс',
            kinetic3:  'билайн',
            ph_ist3:  'билайн телефоны',
            ph_ist2:  'мтс телефоны',
            sms_bln: 'билайн нейминги'
        };
    const [form, setForm] = useState<{
        leadType: string;
        pipelineId: number,
        statusId: number,
        cityId: number,
        entityId: number,
        tag: string,
        utmSource: string,
        perevodKc: boolean,
        pid?: string,
    }>({
        leadType: "",
        pipelineId: 0,
        statusId: 0,
        cityId: 0,
        entityId: 0,
        tag: "",
        utmSource: "",
        perevodKc: false,
        pid: '',
    })

    const domainRef = useRef<HTMLTextAreaElement>(null)
    const phoneRef = useRef<HTMLTextAreaElement>(null)

    const readDomains = async () => {
        let response = await useCases?.segmentUseCase.ReadSegmentDomainsList(id!)
        if (response instanceof Error) {

        } else {
            setDomains(response!)
        }
    }

    const readPhones = async () => {
        let response = await useCases?.segmentUseCase.ReadSegmentPhonesList(id!)
        if (response instanceof Error) {

        } else {
            setPhones(response!)
        }
    }

    const accordionCollapseClick = (e: any) => {

        let accordion = e.currentTarget.parentElement!.querySelector('.accordion-collapse')
        let button = e.currentTarget.parentElement!.querySelector('.accordion-button')

        if (!accordion) {
            return
        }

        if (accordion.classList.contains("collapse")) {
            accordion.classList.remove("collapse")
            button.classList.remove('collapsed')
        } else {
            accordion.classList.add("collapse")
            button.classList.add('collapsed')
        }

    }

    const addDomainsList = async () => {
        let list = domainRef.current?.value.split('\n')
        let newDomains = domains
        if (list) {
            for (let index = 0; index < list.length; index++) {
                let domainItem: string = list[index];
                let response = await useCases?.segmentUseCase.CreateDomainForSegment(id!, domainItem)

                if (response instanceof Error) {
                    let newErrorsDomain = errorsDomainCreate
                    newErrorsDomain.push(domainItem + " - " + response.message)
                    setErrorDomainsCreate([...newErrorsDomain])
                } else {
                    let domain = new SegmentDomain(response!.Id(), response!.SegmentId(), response!.Domain(), response!.CreatedAt())
                    newDomains.push(domain)
                }
            }
            setDomains([...newDomains])
        }
    }

    const addPhoneList = async () => {
        let list = phoneRef.current?.value.split('\n')
        let newPhones = phones
        if (list) {
            for (let index = 0; index < list.length; index++) {
                let domainItem: string = list[index];
                let response = await useCases?.segmentUseCase.CreatePhoneForSegment(id!, domainItem)

                if (response instanceof Error) {
                    let newErrorsPhone = errorsPhoneCreate
                    newErrorsPhone.push(domainItem + " - " + response.message)
                    setErrorPhoneCreate([...newErrorsPhone])
                } else {
                    let phone = new SegmentPhone(response!.Id(), response!.SegmentId(), response!.Phone(), response!.CreatedAt())
                    newPhones.push(phone)
                }
            }
            setPhones([...newPhones])
        }
    }

    const deleteDomain = async (index: number) => {
        let response = await useCases?.segmentUseCase.DeleteDomainOfSegment(domains[index].Id())
        if (response instanceof Error) {

        } else {
            let domainsNew = domains.filter((domain, i) => i != index)
            setDomains([...domainsNew])
        }
    }

    const deletePhone = async (index: number) => {
        let response = await useCases?.segmentUseCase.DeletePhoneOfSegment(phones[index].Id())
        if (response instanceof Error) {

        } else {
            let phonesNew = phones.filter((phone, i) => i != index)
            setPhones([...phonesNew])
        }
    }

    const readSegmentById = async (id: UniqueId) => {
        let response = await useCases?.segmentUseCase.ReadSegmentById(id)
        if (response instanceof Error) {

        } else {
            setForm({
                leadType: response?.LeadType()!,
                pipelineId: response?.PipelineId()!,
                statusId: response?.StatusId()!,
                cityId: response?.CityId()!,
                entityId: response?.EntityId()!,
                tag: response?.Tag()!,
                utmSource: response?.UtmSource()!,
                perevodKc: response?.PerevodKc()!,
                pid: response?.Pid()!,
            })
        }
    }

    const setPid = () => {
        useCases?.segmentUseCase.UpdateSegmentIntegration(id!, form.leadType!, form.pipelineId!, form.statusId!, form.cityId!, form.entityId!, form.tag!, form.utmSource!, form.perevodKc!, form.pid!, () => { })
    }

    useEffect(() => {
        try {
            readDomains()
            readPhones()
        } catch (e) {

        }

    }, [])

    useEffect(() => {
        if (id) {
            readSegmentById(id)
        }
    }, [id])

    return (
        <div className="layout-wrapper layout-content-navbar" >
            <div className="layout-container">
                <Menu />
                <div className="content-wrapper" >
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <div className="row mx-1">
                            <div className="col-sm-12 col-md-12">
                                <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Канал /</span> Редактировать канал</h4>
                            </div>
                            <div className="bs-stepper wizard-modern wizard-modern-example mt-2">
                                <div className="bs-stepper-header">

                                    <div className="line">
                                        <i className="ti ti-chevron-right"></i>
                                    </div>

                                </div>
                            </div>
                            <div className="accordion mt-3" id="accordionExample">
                                <div className="card accordion-item" style={{padding:'10px'}}>
                                    <label htmlFor={"pid_select"} className="form-label">Выберите PID</label>
                                    <select onChange={(ev) => {
                                        setForm({
                                            ...form,
                                            pid: (ev.target.value) in pidList ? ev.target.value : undefined,
                                        })
                                    }} value={form.pid} className="form-select mb-3">
                                        <option key={'default'}>Выберите pid</option>
                                        {Object.entries(pidList).map(([pid, value]) => (
                                            <option key={pid} value={pid}>{value}</option>))}
                                    </select>
                                    <div className="col-lg-1">
                                        <button type="button" className="btn btn-primary waves-effect waves-light"
                                                style={{fontSize: 12}} onClick={() => {
                                            setPid()
                                        }}><Check size={16} style={{marginRight: 2}}/>Сохранить
                                        </button>
                                    </div>
                                </div>
                                <div className="card accordion-item">
                                    <h2 className="accordion-header" id="headingOne" onClick={accordionCollapseClick}>
                                        <button type="button" className="accordion-button collapsed"
                                                data-bs-toggle="collapse" data-bs-target="#accordionOne"
                                                aria-expanded="false" aria-controls="accordionOne">
                                            Домены
                                        </button>
                                    </h2>

                                    <div className="accordion-collapse collapse" data-bs-parent="#accordionExample"
                                         style={{padding: 20}}>
                                        <ul className="list-group">
                                            {domains.map((domain, index) => <li key={index}
                                                className="list-group-item d-flex justify-content-between align-items-center">{domain.Domain()}
                                                <button onClick={() => {
                                                    deleteDomain(index)
                                                }} className="btn btn-danger" style={{padding: 5}}><Trash size={20}/>
                                                </button>
                                            </li>)}
                                        </ul>
                                        {errorsDomainCreate.length > 0 ?
                                            <div style={{marginTop: 10}} className="alert alert-danger mb-5"
                                                 role="alert">Ошибки: <br/>{errorsDomainCreate.map((error) => <>{error}<br/></>)}
                                            </div> : <></>}
                                        <div className="row mb-3 mt-3 align-self-center" style={{ display: "flex", justifyItems: "end", alignItems: "end" }}>
                                            <div className="col-lg-10">
                                                <textarea style={{ minHeight: 150 }} ref={domainRef} className="form-control" placeholder="Введите новый домен или список доменов (каждый с новой строчки)"></textarea>
                                            </div>

                                            <div className="col-lg-1">
                                                <button type="button" className="btn btn-primary waves-effect waves-light" style={{ fontSize: 12 }} onClick={() => { addDomainsList() }}><Check size={16} style={{ marginRight: 2 }} />Добавить</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card accordion-item">
                                    <h2 className="accordion-header" id="headingTwo" onClick={accordionCollapseClick}>
                                        <button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionTwo" aria-expanded="false" aria-controls="accordionTwo">
                                            Номера
                                        </button>
                                    </h2>
                                    <div id="accordionTwo" className="accordion-collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{ padding: 20 }}>
                                        <ul className="list-group">
                                            {phones.map((phone, index) => <li className="list-group-item d-flex justify-content-between align-items-center">{phone.Phone()}<button onClick={() => { deletePhone(index) }} className="btn btn-danger" style={{ padding: 5 }}><Trash size={20} /></button></li>)}
                                        </ul>
                                        {errorsPhoneCreate.length > 0 ? <div style={{ marginTop: 10 }} className="alert alert-danger mb-5" role="alert">Ошибки: <br />{errorsPhoneCreate.map((error) => <>{error}<br /></>)} </div> : <></>}
                                        <div className="row mb-3 mt-3 align-self-center" style={{ display: "flex", justifyItems: "end", alignItems: "end" }}>
                                            <div className="col-lg-10">
                                                <textarea style={{ minHeight: 150 }} ref={phoneRef} className="form-control" placeholder="Введите новый домен или список доменов (каждый с новой строчки)"></textarea>
                                            </div>

                                            <div className="col-lg-1">
                                                <button type="button" className="btn btn-primary waves-effect waves-light" style={{ fontSize: 12 }} onClick={() => { addPhoneList() }}><Check size={16} style={{ marginRight: 2 }} />Добавить</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}